
<template>
<div >
      <card-component
      >
      <div class="columns">
        <div class="column"></div>
        <div class="column is-two-thirds"><img src="@/assets/img/404.png"/></div>
        <div class="column"></div>
      </div>

      </card-component>
</div>
</template>

<script>
import CardComponent from '@/components/ui/CardComponent'

export default {
  name: 'Contact',
  components: {
    CardComponent
  },
  data () {
    return {
    }
  }
}
</script>
